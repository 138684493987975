<template>
    <div class="main-cora">
        <b-navbar class="navbar header-new_cora">
            <!-- <b-container class="col-12 container-fluid d-flex align-items-center justify-content-start topo-new__cora"> -->
                <img :src="cora" class="logo-cliente-sesi-new__cora ml-1" alt="Logo" >
                <div>
                    <h1 class="logo-header-new__cora">CORA</h1>
                    <h2 class="subtitulo-new__cora">Assistente virtual do Sesi</h2>
                </div>
            <!-- </b-container> -->
        </b-navbar>
        <div
          v-if="projectStatus == projectStatusTypeEnum.HOMOLOGACAO"
          class="tag_font_style tag_custom_style homologation_stripe_color"
        >
          AMBIENTE DE HOMOLOGAÇÃO
        </div>
        <div
          v-if="projectStatus == projectStatusTypeEnum.TREINAMENTO"
          class="tag_font_style tag_custom_style training_stripe_color"
        >
          AMBIENTE DE TREINAMENTO
        </div>
        <div class="chat-new__cora" style="height: 70.5%;">
            <b-row class="linha-new__cora" ref="chatNewContainer">
                <b-col lg="12" v-for="(dado, index) in dados" :key="index">
                    <div v-if="dado.loading"><!-- Aqui fica if loading -->
                         <div class="d-flex espaco-new__cora" >
                            <img :src="cora" class="img-cora-chat-new-cora" alt="Cora">
                            <p class="texto-chat-new__cora loading-dots-new__cora"></p>
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-grow-1" v-if="dado.loading == false && !dado.dados_pessoais">
                        <div class="d-flex espaco-new__cora">
                            <img :src="cora" class="img-cora-chat-new-cora" alt="Cora">
                            <p class="texto-chat-new__cora"><span v-html="dado.pergunta_msg"></span></p>
                        </div>
                        <div class="answer-options-div-cora" v-if="dado.opcoes && dado.multivalorado == false " ref="answerOptionsDivCora">
                            <button :class="{'lowlight-cora':opcao.cor, 'answer-option-cora':true, 'btn-voltar':opcao.botao_inicio}" :style="opcao.botao_inicio ? 'margin: 0 auto; margin-top:20px;' : ''" @click="processamentoIteracao(opcao)"  v-for="opcao in dado.opcoes" v-if="!opcao.digitavel" :disabled="opcao.desabilita_botao">
                                <span v-html="opcao.descricao_opcao"> </span>
                            </button>
                        </div>
                        <div class="answer-options-div-cora" v-if="dado.opcoes && dado.multivalorado" ref="answerOptionsDivCoraMultivalorado">
                            <button @click="adicionarOpcoesEscolhidas(opcao,dado)" :key="opcaoIndex" v-for="(opcao,opcaoIndex) in dado.opcoes" :class="{ 'lowlight-cora': opcao.cor_multivalorado,'answer-option-cora': true,' d-flex justify-content-start':true}" :disabled="opcao.desabilita_botao_multivalorado">
                               <input type="checkbox" :checked="opcao.botao_multivalorado" :disabled="opcao.desabilita_botao_multivalorado" class="ml-1">
                               <label for="Opcao" class="form-label label-class ml-1"> <span v-html="opcao.descricao_opcao"> </span></label>
                            </button>
                        </div>
                        <div class="answer-options-div-cora" v-if="dado.opcoes && dado.multivalorado">
                            <button  @click="pegarProximaIteracaoMultiploSelect()" :class="{ 'lowlight-cora': dado.qtd_opcoes_multivaloradas == 0 || dado.qtd_opcoes_multivaloradas > dado.multivalorado || dado.desabilita_botao_enviar_multivalorado,'answer-option-cora': true,}" :disabled="dado.qtd_opcoes_multivaloradas == 0 || dado.qtd_opcoes_multivaloradas > dado.multivalorado || dado.desabilita_botao_enviar_multivalorado" :title="'escolha de 1 a '+ dado.multivalorado+' opções' " style="margin: 0 auto; margin-top:10px">
                                Enviar
                            </button>
                        </div>
                    </div>
                    <div class="col-12 chat  d-flex justify-content-end"  v-if="dado.opcao_escolhida && !dado.multivalorado" ref="resposta">
                        <p class="user-msg-cora"><span v-html="dado.opcao_escolhida"></span></p>
                    </div>
                    <div class="col-12 chat-multivalorado-cora  d-flex justify-content-end" v-for="opcao in dado.opcao_escolhida_multivalorada"  v-if="opcao_escolhida_multivalorada.length > 0 && dado.multivalorado">
                      <p class="user-msg-cora"><span v-html="opcao"></span></p>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="send-msg">
            <div class="cora-emoji">
                <button @click="toogleEmojiPicker" variant="link" class="emoji-picker-cora botao-cora">
                    <img src="@/assets/images/cora/smile.svg"  class="img-smile-cora" alt="Emoji" width="26">
                </button>
                <VEmojiPicker
                    v-if="showEmojiPicker"
                    v-show="showEmojiPicker"
                    :style="{width: '312px', height: '440px', position: 'absolute', bottom: '100px'}"
                    lang="pt-BR"
                    @select="onSelectEmoji"
                    disable-search
                />
            </div>
            <input type="text" @keyup.enter="processamentoIteracao()" ref="inputFoco" class="cora-input send-msg-input-cora" v-mask="mascara" v-model="respostaInput" :disabled="inputDesativado" :placeholder="placeholder">
            <button class="botao-cora" :disabled="botaoEnviar" @click="processamentoIteracao()">
                <img src="@/assets/images/cora/send.svg"  class="send-cora-img" alt="Enviar" width="40">
            </button>
        </div>
        <LoginCora @concluirSalvar="salvarForm" :pessoa="dadosPessoais" v-if="showModal" />
    </div>
</template>
<script>

import { BNavbar, BContainer,BCol, BRow ,BModal} from 'bootstrap-vue'
import cora from '@/assets/images/cora/Cora.png'
import { VEmojiPicker } from 'v-emoji-picker';
import LoginCora from './LoginCora.vue'
import VueMask from 'v-mask'
import { modalGenericFillableErrorWithReload } from '@/libs/sweetalerts'
import { getProjectStatus } from "@/auth/utils";
import { projectStatusTypes } from "@/custom-enum/projectStatusType";
import Swal from 'sweetalert2'

export default {
    components: {
        BNavbar,
        BContainer,
        BCol,
        BRow,
        VEmojiPicker,
        LoginCora,
        VueMask,
        BModal
    },

    setup() {
      const projectStatus = getProjectStatus();

      const projectStatusTypeEnum = projectStatusTypes;
      return {
        projectStatus,
        projectStatusTypeEnum
      };
    },

    data() {
        return {
            cora: cora,
            showEmojiPicker: false,
            opcao_clicada: false,
            dados_pessoais:false,
            placeholder: 'Selecione uma opção (Mensagem Desativada)',
            opcao_escolhida: '',
            inputDesativado: true,
            botaoEnviar: true,
            chave_usuario: null,
            resposta_opcao: null,
            nova_chave_ciclo: null,
            valor_opcao: null,
            chave_ciclo: null,
            id_opcao: null,
            respostas: [],
            api_cora_ciclo: process.env.VUE_APP_API_PROCESSAMENTO_CORA_CICLO,
            api_busca_dados_pessoais: process.env.VUE_APP_API_PROCESSAMENTO_CORA_DADOS_PESSOAIS,
            api_cora_redirecionamento: process.env.VUE_APP_API_PROCESSAMENTO_CORA_REDIRECIONAMENTO,
            send:null,
            respostaInput: [],
            showModal: false,
            mascara: '###.###.###-##',
            dados: [],
            mensagemTeEntendoCora:false,
            opcao_escolhida_multivalorada: [],
            multiploSelect: [],
            dadosPessoais:[],
            parametrosConfirmacao: {},
        }
    },
    watch: {
        'respostaInput':{
            handler: function(val){
               if(this.respostaInput != null){
                    if((this.respostaInput && this.respostaInput.length) && (this.mascara == '###.###.###-##' && this.respostaInput.length == 14) || (this.mascara == '##/##/####' && this.respostaInput.length == 10)){
                        this.botaoEnviar = false
                    }else{
                        this.botaoEnviar = true
                    }
               }

                if(val == null || val == ''){
                    this.botaoEnviar = true
                }
            },

            immediate: true
        },
    },
    mounted(){
        this.removeBtn()
        if(this.$route.params.chave){
            this.verificarRedirecionamento(this.$route.params.chave)
        }else{
            this.processamentoInicial()
        }
    },
    methods: {

        async processamentoInicial(){
           let url = this.api_cora_ciclo

           await this.$http.post(url)
            .then(response => {
                this.dados = response.data.resposta_inicial_usuario
                this.chave_usuario = response.data.chave_usuario
                this.chave_ciclo = response.data.chave_ciclo
            })

            this.delayChat(this.dados)
        },

        async processamentoIteracao(opcao = null){
            if(opcao != null  && opcao != 'confirmarDadosPessoais'){
                this.processamentoIteracaoCasoTenhaOpcaoEscolhida(opcao);
            }else if(opcao == null ){
                // IF para só habilitar fazer a requisicao se o input tiver 14 digitos para cpf ou 10 para Data
                if((this.respostaInput != null || !/[^0-9]/.test(this.respostaInput)) && (this.respostaInput != null && this.respostaInput != '') && ((this.mascara == '###.###.###-##' && this.respostaInput.length == 14) || (this.mascara == '##/##/####' && this.respostaInput.length == 10))){
                    this.processamentoIteracaoCasoOpcaoDigitada();
                }
            }else if(opcao == 'confirmarDadosPessoais'){
              this.processamentoIteracaoConfirmaDadosPessoais();
            }
        },

        async processamentoIteracaoCasoTenhaOpcaoEscolhida(opcao){
            if(this.verificaLinkExternoERedireciona(opcao)){
                this.opcao_clicada = true
                this.opcao_escolhida = opcao.descricao_opcao

                this.atualizaMostraOpcaoEscolhida()

                this.verificaDesabilitaBotoes(opcao)

                let params = null

                if(this.nova_chave_ciclo != null){
                    params = {
                        "chave_usuario": this.chave_usuario,
                        "chave_ciclo": this.nova_chave_ciclo,
                        "resposta_opcao": opcao.id_opcao,
                    }
                }else if(this.nova_chave_ciclo == null){
                    params = {
                        "chave_usuario": this.chave_usuario,
                        "chave_ciclo": this.chave_ciclo,
                        "resposta_opcao": opcao.id_opcao,
                    }
                }

                let buscandoRequisicao = false

                setTimeout(() => {
                    if(buscandoRequisicao){
                        this.modalCoraLoading('Houve um problema ao se conectar ao servidor. Tentando reconectar novamente...')
                    }

                }, 5000)

                buscandoRequisicao = true

                await this.$http.post(this.api_cora_ciclo, params, {timeout: 60000})
                .then(response => {
                    buscandoRequisicao = false
                    this.fecharModalCora()

                    let novasMensagens = null

                    if(response.data.resposta_inicial_usuario){
                        novasMensagens = response.data.resposta_inicial_usuario.map((res) => ({
                            ...res,
                                delayCarragamento: true,
                                qtd_opcoes_multivaloradas: 0,
                            }));

                            this.nova_chave_ciclo = response.data.nova_chave_ciclo

                    }else{
                        novasMensagens = response.data.map((res) => ({
                        ...res,
                            delayCarragamento: true,
                            qtd_opcoes_multivaloradas: 0,
                        }));

                    }

                    this.dados = [...this.dados, ...novasMensagens];
                    this.delayChatRespostas(this.dados)
                    this.verificaBotaoInicio()

                })
                .catch(error => {
                    buscandoRequisicao = false
                    this.fecharModalCora()
                    if(error.message == "Cannot read properties of null (reading 'status')" || !error.request){
                      modalGenericFillableErrorWithReload(
                        "Houve um problema com a sua conexão. Tente novamente mais tarde"
                      )
                    }else{
                      modalGenericFillableErrorWithReload(
                        "Servidor indisponível. Tente novamente mais tarde"
                      )
                    }
                })
            }
        },

        async processamentoIteracaoCasoOpcaoDigitada(){
            this.opcao_clicada = true
            this.opcao_escolhida = this.respostaInput

            this.atualizaMostraOpcaoEscolhida()


            let indice = this.dados.length - 1
            let opcao = this.dados[indice]


            let cpf = null
            let data = null

            if(this.respostaInput != null && this.respostaInput.length == 14){
                //retirando mascara do CPF
                cpf = this.respostaInput.replace(/\D/g, '')
            }

            if(this.respostaInput != null && this.respostaInput.length == 10 && opcao.opcoes[0].descricao_opcao == "__/__/____"){
                data = this.respostaInput.split("/");
                data = data[2] + '-' + data[1] + '-' + data[0]
            }

            let texto = cpf != null ? cpf : data
            this.desativaInput()
            if(opcao.opcoes){
                let params = {
                    "chave_usuario": this.chave_usuario,
                    "chave_ciclo": this.chave_ciclo,
                    "resposta_opcao": opcao.opcoes[0].id_opcao,
                    "valor_opcao": texto
                }
                let buscandoRequisicao = false

                setTimeout(() => {
                    if(buscandoRequisicao){
                        this.modalCoraLoading('Houve um problema ao se conectar ao servidor. Tentando reconectar novamente...')
                    }

                }, 5000)

                buscandoRequisicao = true

                await this.$http.post(this.api_cora_ciclo, params, {timeout: 60000})

                .then(response => {
                    buscandoRequisicao = false
                    this.fecharModalCora()
                    if(response.data[0].dados_pessoais && response.data[0].url){
                        this.id_opcao = response.data[0].id_opcao
                    }

                    if(response.data[0].dados_pessoais){
                        this.buscaDadosPessoais()
                    }

                    const novaMensagem = response.data.map((res) => ({
                    ...res,
                        delayCarragamento: true,
                    }));
                    this.dados = [...this.dados, ...novaMensagem];
                    this.delayChatRespostas(this.dados)
                    this.opcao_clicada = false
                })
                .catch(error=> {
                    buscandoRequisicao = false
                    this.fecharModalCora()

                    if(error.message == "Cannot read properties of null (reading 'status')" || !error.request){
                      modalGenericFillableErrorWithReload(
                        "Houve um problema com a sua conexão. Tente novamente mais tarde"
                      )
                    }else{
                      modalGenericFillableErrorWithReload(
                        "Servidor indisponível. Tente novamente mais tarde"
                      )
                    }
                })
            }
        },

        async processamentoIteracaoConfirmaDadosPessoais(){
            let params = {
                    "chave_usuario": this.chave_usuario,
                    "chave_ciclo": this.chave_ciclo,
                    "resposta_opcao": this.id_opcao,
                }

                let buscandoRequisicao = false

                setTimeout(() => {
                    if(buscandoRequisicao){
                        this.modalCoraLoading('Houve um problema ao se conectar ao servidor. Tentando reconectar novamente...')
                    }

                }, 5000)

                buscandoRequisicao = true

                await this.$http.post(this.api_cora_ciclo, params, {timeout: 60000})
                .then(response => {
                    buscandoRequisicao = false
                    this.fecharModalCora()
                    this.salvarConfirmaDadosPessoais()
                    let novaMensagem = null

                    if(response.data.resposta_inicial_usuario){
                        novaMensagem = response.data.resposta_inicial_usuario.map((res) => ({
                            ...res,
                            delayCarragamento: true,
                            qtd_opcoes_multivaloradas: 0,
                        }));

                        this.nova_chave_ciclo = response.data.nova_chave_ciclo
                    }else{
                        novaMensagem = response.data.map((res) => ({
                            ...res,
                            delayCarragamento: true,
                            qtd_opcoes_multivaloradas: 0,
                        }));
                    }

                    this.dados = [...this.dados, ...novaMensagem];
                    this.delayChatRespostas(this.dados)
                    this.verificaBotaoInicio()
                })
                .catch(error=> {
                    buscandoRequisicao = false
                    this.fecharModalCora()

                    if(error.message == "Cannot read properties of null (reading 'status')" || !error.request){
                      modalGenericFillableErrorWithReload(
                        "Houve um problema com a sua conexão. Tente novamente mais tarde"
                      )
                    }else{
                      modalGenericFillableErrorWithReload(
                        "Servidor indisponível. Tente novamente mais tarde"
                      )
                    }
                })
        },

        async pegarProximaIteracaoMultiploSelect(){
            let resposta_opcao_multivalorada = this.multiploSelect.map((op) => op.id_opcao)
            this.opcao_escolhida_multivalorada = this.multiploSelect.map((op) => op.descricao_opcao)

            this.$set(this.dados, this.dados.length - 1, {
                ...this.dados[this.dados.length - 1],
                opcao_escolhida_multivalorada: this.opcao_escolhida_multivalorada,
            });

            this.verificaDesabilitaBotoesMultivalorados();

            let params={};
            if(this.nova_chave_ciclo == null){
                params = {
                    'chave_ciclo': this.chave_ciclo,
                    'chave_usuario': this.chave_usuario,
                    'resposta_opcao_multivalorada': resposta_opcao_multivalorada
                }
            }else{
                params = {
                    'chave_ciclo': this.nova_chave_ciclo,
                    'chave_usuario': this.chave_usuario,
                    'resposta_opcao_multivalorada': resposta_opcao_multivalorada
                }
            }

            let buscandoRequisicao = false

            setTimeout(() => {
                if(buscandoRequisicao){
                    this.modalCoraLoading('Houve um problema ao se conectar ao servidor. Tentando reconectar novamente...')
                }

            }, 5000)

            buscandoRequisicao = true

            await this.$http.post(this.api_cora_ciclo, params, {timeout: 60000})

            .then(response => {
                buscandoRequisicao = false
                this.fecharModalCora()
                if(response.data.length > 0){
                    // Adiciona Propriedade delayCarragamento para carregar a div após o delay com os três pontinhos da animação
                    const novasMensagens = response.data.map((res) => ({
                        ...res,
                        delayCarragamento: true
                    }));

                    this.dados = [...this.dados, ...novasMensagens];
                    this.verificaBotaoInicio()

                    //Verifica caso tenha questões multivaloradas na primeira iteração quando pega a chave de usuario e adiciona atributo botao_multivalorado e qtd_opcoes_multivaloradas
                    this.verificaQuestoesMultivaloradosPrimeiro()
                }

                this.delayChatRespostasMultivaloradas(this.dados)
                // Limpa multiploSelect apenas para esta instância específica
                this.$set(this, 'multiploSelect', []);
            })
            .catch((error) => {
                buscandoRequisicao = false
                this.fecharModalCora()

                if(error.message == "Cannot read properties of null (reading 'status')" || !error.request){
                  modalGenericFillableErrorWithReload(
                    "Houve um problema com a sua conexão. Tente novamente mais tarde"
                  )
                }else{
                  modalGenericFillableErrorWithReload(
                    "Servidor indisponível. Tente novamente mais tarde"
                  )
                }
            })
        },

       async buscaDadosPessoais(){
            let url = this.api_busca_dados_pessoais
            this.desativaInput()
            let params = {
                "chave_usuario": this.chave_usuario,
                "chave_ciclo": this.chave_ciclo,
                "resposta_opcao": "buscarDadosPessoais",
            }

            await this.$http.post(url, params)
            .then(response => {
                this.dadosPessoais = response.data
            })
            this.abrirModal()
        },

         confirmaDadosPessoais(){
            this.processamentoIteracao('confirmarDadosPessoais')

        },

        salvarConfirmaDadosPessoais(){
            this.$http.post(this.api_busca_dados_pessoais, this.parametrosConfirmacao)
            .then()
            .catch(() => {
              modalGenericFillableErrorWithReload(
                "Error ao salvar dados pessoais. Tente novamente mais tarde"
              )
            })
        },

        toogleEmojiPicker() {
            this.showEmojiPicker = !this.showEmojiPicker;
        },

        onSelectEmoji(emoji) {
            console.log(emoji);
        },

        fechaModalPessoa() {
            this.showModal = false
        },
        delay(seconds) {
            const milliseconds = seconds * 1000;
            return new Promise(resolve => setTimeout(resolve, milliseconds))
        },

        async delayChatRespostas(mensagem, delay = 2){

            for (let i = 0; i < mensagem.length; i++) {
                if(mensagem[i].delayCarragamento == true){

                    this.$set(mensagem, i, { ...mensagem[i], loading: true })

                    this.$nextTick(() => {
                        this.scrollChatToBottom();
                    });


                    if(mensagem[i].delay_anterior){
                        await this.delay(mensagem[i].delay_anterior);
                        this.$set(mensagem, i, { ...mensagem[i], loading: false })
                    }else{
                        await this.delay(delay)
                        this.$set(mensagem, i, { ...mensagem[i], loading: false })
                    }

                    mensagem[i].delayCarragamento = false
                }

            }

            // Para scroll focar na ultima div adicionada
            await this.delay(0.1);

            this.scrollChatToBottom();

                this.delay(0.5).then(() => {
                    this.verificarRepostasCpfEData()
                });


        },

        async delayChatRespostasMultivaloradas(mensagem, delay = 2){
            for (let i = 0; i < mensagem.length; i++) {
                if(mensagem[i].delayCarragamento == true){

                    this.$set(mensagem, i, { ...mensagem[i], loading: true })

                    this.$nextTick(() => {
                        this.scrollChatToBottomMultivalor();
                    });
                    await this.delay(0.1);
                    this.scrollChatToBottomMultivalor();

                    if(mensagem[i].delay_anterior){
                        await this.delay(mensagem[i].delay_anterior);
                        this.$set(mensagem, i, { ...mensagem[i], loading: false })
                    }else{
                        await this.delay(delay)
                        this.$set(mensagem, i, { ...mensagem[i], loading: false })
                    }


                    mensagem[i].delayCarragamento = false
                }

            }

            // Para scroll focar na ultima div adicionada
            await this.delay(0.1);
            this.scrollUltimoMultivalorado();
        },

        scrollUltimoMultivalorado(){
            let ultimoElemento  = this.$refs.chatNewContainer.children[this.$refs.chatNewContainer.children.length - 1];

            if(ultimoElemento.lastElementChild != null){
                ultimoElemento.lastElementChild.scrollIntoView({ behavior: "smooth" });

                if(this.$refs.answerOptionsDivCoraMultivalorado){
                    const answerOptionsDivCoraMultivalorado = this.$refs.answerOptionsDivCoraMultivalorado[this.$refs.answerOptionsDivCoraMultivalorado.length - 1];
                     //Verifique se o elemento existe
                    if (answerOptionsDivCoraMultivalorado) {
                        // Role até o elemento usando scrollIntoView
                        answerOptionsDivCoraMultivalorado.scrollIntoView({ behavior: 'smooth' });
                    }
                }
            }
        },


        scrollChatToBottomMultivalor() {
            const lastElement = this.$refs.chatNewContainer.lastChild;

            if (lastElement) {
                lastElement.scrollIntoView({ behavior: "smooth" });
            }
        },


        async delayChat(mensagem){
            for (let i = 0; i < mensagem.length; i++) {
                this.$set(mensagem, i, { ...mensagem[i], loading: true })

                await this.delay(3)
                this.$set(mensagem, i, { ...mensagem[i], loading: false })
            }
        },

        async deleyChatRedirecionamento(mensagem){
            this.$set(mensagem, 0, { ...mensagem, link: false, loading: true });

            await this.delay(3);

            this.$set(mensagem, 0, { ...mensagem, link: false, loading: false });
        },


        scrollChatToBottom() {
            const lastElement = this.$refs.chatNewContainer.lastChild;

            if (lastElement) {
                lastElement.scrollIntoView({ behavior: "smooth" });
            }
        },

        scrollToBottomResponse() {
            this.$nextTick(() => {
                this.$refs.chatNewContainer.scrollTop = this.$refs.chatNewContainer.scrollHeight;
            });
        },

        abrirModal(){
            this.showModal = true
        },

        fecharModal(){
            this.showModal = false
        },

        atualizaMostraOpcaoEscolhida(){
            this.$set(this.dados, this.dados.length - 1, {
                    ...this.dados[this.dados.length - 1],
                    opcao_escolhida: this.opcao_escolhida,
            });
        },

        verificarRepostasCpfEData(){
            let indice = this.dados.length
            let opcao = this.dados[indice - 1]

            if(opcao.opcoes){
                if(opcao.opcoes[0].descricao_opcao == '___.___.___-__'){
                    this.mascara = '###.###.###-##'
                    this.placeholder = 'CPF'
                    this.dados_pessoais = true
                    this.respostas.push(opcao)

                    this.$nextTick(() => {
                        // Após atualizar o DOM para mostrar o campo CPF, foca no input
                        this.$refs.inputFoco.focus();
                    });
                }
                if(opcao.opcoes[0].descricao_opcao == '__/__/____'){
                    this.mascara = '##/##/####'
                    this.placeholder = 'Data de nascimento'
                    this.dados_pessoais = true
                    this.respostas.push(opcao)
                    this.$nextTick(() => {
                        // Após atualizar o DOM para mostrar o campo CPF, foca no input
                        this.$refs.inputFoco.focus();
                    });
                }
                if(this.dados_pessoais){
                    this.respostaInput = null
                    this.inputDesativado = false
                    this.dados_pessoais = false
                }

                opcao.opcoes.map((op) => {
                    if(op.digitavel == false){
                        this.inputDesativado = true
                        this.respostaInput = null
                        this.placeholder = 'Selecione uma opção (Mensagem Desativada)'
                    }
                });
            }
            else{
                this.respostaInput = null
                this.inputDesativado = true
            }


        },

        desativaInput(){
            this.inputDesativado = true
            this.respostaInput = null
            this.placeholder = 'Selecione uma opção (Mensagem Desativada)'
        },

        verificaDesabilitaBotoes(opcao){
            //Percorre array de dados e desabilita botões que já foram clicados
            this.dados[this.dados.length - 1].opcoes.forEach((op) => {
                op.selecionado = op.id_opcao === opcao.id_opcao;
                op.selecionado_cor = op.id_opcao != opcao.id_opcao;
            });

            //Verifica se objeto tem opcoes e se tem desabilita botões que não foram clicados
            this.dados.forEach((dado) => {
                if (dado.opcoes) {
                    dado.opcoes.map((op) => {
                        op.desabilita_botao = op.selecionado || this.opcao_clicada;
                        op.cor = op.selecionado_cor;
                    });
                }
            });
        },

        salvarForm(pessoa){
             this.fecharModal()
             this.parametrosConfirmacao= {
                "chave_usuario": this.chave_usuario,
                "chave_ciclo": this.chave_ciclo,
                "resposta_opcao": "confirmarDadosPessoais",
                "nome": pessoa.pessoa.nome,
                "nascimento": pessoa.pessoa.nascimento,
                "genero": pessoa.pessoa.id_genero,
                "telefone": pessoa.pessoa.telefone,
                "estado_civil": pessoa.pessoa.id_estado_civil,
                "renda_bruta": pessoa.pessoa.renda_bruta,
                "numero_filhos" : pessoa.pessoa.numero_filhos,
                "escolaridade": pessoa.pessoa.id_escolaridade
             };
             this.confirmaDadosPessoais()
        },

        removeBtn(){
           const botao = document.querySelector('.btn-scroll-to-top')
           botao.remove()
        },

        verificaBotaoInicio(){
           this.dados.map((dado) => {
                if (dado.opcoes) {
                    dado.opcoes.map((op) => {
                        op.botao_inicio = op.descricao_opcao === 'Voltar ao início' || op.descricao_opcao === '<b>Voltar ao início</b>';
                    });
                }
            });
        },
        adicionarOpcoesEscolhidas(opcao,dado){
            opcao.botao_multivalorado = !opcao.botao_multivalorado;
            dado.desabilita_botao_enviar_multivalorado = false
            this.multiplaEscolhaSelect(opcao,dado)
        },

        multiplaEscolhaSelect(opcao,dado) {
            if(opcao.descricao_opcao != "<b>Não. Prefiro não me identificar nesse momento</b>" && opcao.descricao_opcao != "<b>Não quero receber apoio</b>"){
            this.verificaMultiploSelect(opcao,dado)
            }else if(opcao.descricao_opcao == "<b>Não. Prefiro não me identificar nesse momento</b>" || opcao.descricao_opcao == "<b>Não quero receber apoio</b>"){
                this.ativaOuDesativaOutrosBotoesCasoSejaOpcaoUnica(dado,true)

                if(opcao.botao_multivalorado){
                    this.multiploSelect.push(opcao)
                }else{
                    this.verificaMultiploSelect(opcao,dado)
                    this.ativaOuDesativaOutrosBotoesCasoSejaOpcaoUnica(dado,false)

                }
            }
            dado.qtd_opcoes_multivaloradas = this.multiploSelect.length
        },

        ativaOuDesativaOutrosBotoesCasoSejaOpcaoUnica(dado,ativado){
            dado.opcoes.forEach((op) => {
                if(op.descricao_opcao != "<b>Não. Prefiro não me identificar nesse momento</b>" && op.descricao_opcao != "<b>Não quero receber apoio</b>"){
                    op.botao_multivalorado = false
                    this.$set(this, 'multiploSelect', []);
                    op.desabilita_botao_multivalorado = ativado
                    this.$forceUpdate();
                }
            });
        },

        verificaMultiploSelect(opcao,dado){
            if(opcao.botao_multivalorado){
                this.multiploSelect.push(opcao)
            }else{
                const index = this.multiploSelect.indexOf(opcao);
                if (index !== -1) {
                    this.multiploSelect.splice(index, 1);
                }


                if(this.multiploSelect.length  == 0){
                    dado.desabilita_botao_enviar_multivalorado = true
                }
            }
        },

        verificaDesabilitaBotoesMultivalorados(){
            this.dados.map((dado) => {
                if (dado.opcoes) {
                    dado.opcoes.map((op) => {
                        if(op.multivalorado != false){
                            if(op.botao_multivalorado){
                                op.cor_multivalorado = op.botao_multivalorado ? false : true ;
                                op.desabilita_botao_multivalorado = true
                            }else{
                                op.cor_multivalorado = true;
                                op.desabilita_botao_multivalorado = true
                            }
                        }

                    });
                }
            });

            //Desabilita o botão de Enviar de questoes multivaloradas
            this.dados.map((dado) => {
                dado.desabilita_botao_enviar_multivalorado = true
            });
        },

        verificaQuestoesMultivaloradosPrimeiro(){
            this.dados.map((dado) => {
                if (dado.opcoes && dado.multivalorado != false) {
                    dado.qtd_opcoes_multivaloradas = 0
                    dado.desabilita_botao_enviar_multivalorado = true
                    dado.opcoes.map((op) => {
                        if(op.botao_multivalorado != true){
                            op.botao_multivalorado = false
                        }
                    });
                }
            });
        },

        verificaLinkExternoERedireciona(opcao){
            if(opcao.link_externo){
                window.location.href = opcao.link_externo
                return false
            }

            return true
        },

        async verificarRedirecionamento(){
            let chave = this.$route.params.chave
            let url = this.api_cora_redirecionamento+chave

            await this.$http.get(url)
            .then(response => {
                if(response.data.usuario_ciclo_respondido){
                    this.mensagemTeEntendoCora = true
                    this.dados = response.data.resposta_inicial_usuario
                }else{
                    this.dados = response.data.resposta_inicial_usuario
                    this.chave_usuario = response.data.chave_usuario
                    this.chave_ciclo = response.data.chave_ciclo
                }

            })
            .catch(() => {
              modalGenericFillableErrorWithReload(
                "Ocorreu um erro. Tente novamente mais tarde"
              )
            })

            if(this.mensagemTeEntendoCora){
                this.deleyChatRedirecionamento(this.dados)
            }else{
                this.delayChat(this.dados)
            }


        },

        modalCoraLoading(texto){
            let timerInterval;
            Swal.fire({
                width: 550,
                imageUrl: require('@/assets/custom-icons/cora-icons/warning-red-icon.png'),
                imageWidth: 80,
                imageHeight: 80,
                title: 'Erro na conexão',
                allowOutsideClick: false,
                html: `<span class="">${texto}</span>`,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },
                willClose: () => {
                    clearInterval(timerInterval);
                }
            })
        },

        fecharModalCora(){
            Swal.close()
        },

    },

}

</script>

<style>
.modal .modal-header .close {
    display: none;
}
body {
    background: linear-gradient(154.93deg,#A6DFFF 17.96%,#2772C0 90.15%);
}
.btn-voltar {
    background: none !important;
    border: 2px solid rgba(0,32,97,.5019607843) !important;
    color: #002060 !important;
    font-weight: 600;
    margin-top: 1em;
}
.cora-emoji{
    width: 5%;
}
.cora-input{
    width: 85%;
}
/* HEADER */

.header-new_cora{
    height: 15%;
    background: linear-gradient(45deg,#1265BB .32%,#4EA4FE 100%);
    border-radius: 6px 6px 0 0;
    display: flex;
    align-items: center;
    color: #fff;
}
.topo-new__cora{
    height: 112px;
    background: linear-gradient(45deg,#1265BB .32%,#4EA4FE 100%);
    display: flex;
    align-items: center;
    color: #fff;
}
.label-class{
    color: #fff;
}
.logo-header-new__cora {
    font-size: 2.3rem;
    font-weight: 300;
    color: #fff;
    font-family: "Montserrat", sans-serif;

}
.subtitulo-new__cora {
    font-size: 1.6rem;
    font-weight: 200;
    color: #fff;
    font-family: "Montserrat", sans-serif;

}
.img-cora-chat-new-cora{
    width: 48px;
    height: 48px;
    margin-right: .7rem;
}
.chat-multivalorado-cora{
    flex: 1;
    background-color: #e5eef7;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
}

.main-cora {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40vw;
    height: 90vh;
    background-color: #fff;
    border-radius: 6px;
    min-width: 600px;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
}
.answer-options-div-cora{
    max-width: 65%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 3.5rem;
    margin-bottom: 14px;
}

.lowlight-cora {
    background-color: #00206180 !important;
    cursor: auto;
}

.answer-option-cora{
    width: 45%;
    max-width: 45%;
    min-height: 30px;
    padding: 1% 2%;
    background-color: #002060;
    color: #fff;
    border-radius: 20px;
    box-shadow: 0 4px 8px #0000001f;
    font-size: 13px;
    justify-content: center;
    align-items: center;
    margin: 1% 2%;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    cursor: pointer;
    border: none;
}

.user-msg-cora {
    margin-left: auto;
    background: linear-gradient(45deg,#1265BB .32%,#4EA4FE 100%);
    color: #fff !important;
    margin-bottom: 14px;
    font-size: 16px;
}

.logo-cliente-sesi-new__cora{
    width: 12.75%;
    margin: 3%;
}

/* CHAT */

.chat-new__cora{
    flex: 1; /* Ocupa todo o espaço disponível */
    background-color: #e5eef7;
    padding: 6% 2.6% 2.5%;
    overflow-y: auto;
    overflow-x: hidden;
}
.chat-new__cora p {
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 65%;
    padding: 8px 16px;
    border-radius: 5px;
    font-weight: 300;
    line-height: 1.5;
    text-align: left;
    overflow-wrap: break-word;
    color: #000000;
}
.send-msg{
    width: 100%;
    height: 10%;
    padding: 1% 2%;
    border-radius: 0 0 6px 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
}

.send-msg-input-cora{
    width: 100%;
    background-color: #fff;
    font-family: "Montserrat", sans-serif;
    margin: 0 .5% 0 1.5%;
    outline: none !important;
    border: none !important;
}

.emoji-picker-cora {
    display: flex;
    align-items: center;
    width: 5%;
    height: 100%;
}

.botao-cora{
    background-color: transparent;
    width: 10%;
    border: none;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.img-smile-cora{
    margin-left:12px;
}

.espaco-new__cora{
    margin-bottom: 2.3%;
}


/* ANIMAÇÕES */
@keyframes dotsAnimation {
    0%, 20% {
        content: ' .';
    }
    40%, 60%{
        content: ' ..';
    }
     80%,100% {
        content: ' ...';
    }

}
.texto-chat-new__cora{
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 65%;
    padding: 8px 16px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
    overflow-wrap: break-word;
    background-color: #fff;
    margin: 0 0 10px;
}

.loading-dots-new__cora::after {
    content: ' ....';
    animation: dotsAnimation 1s infinite;
    display: inline-block;
}

/* RESPONSIVIDADE */

@media (min-width: 2200px) {
    .header-new_cora{
        height: 25% !important;
        max-height: 200px
    }
}

@media (max-width: 768px) {
    .main-cora {
        min-width: 400px;
        width: 100vw;
        height: 100vh;
        top: 0%;
        left: 0%;
        border-radius: 0;
        transform: translate(0);
        overflow: hidden;
    }

    .logo-header-new__cora{
        font-size: 5.4vw;
    }
    .header-new_cora{
        border-radius: 0px;
    }
}

@media (max-width: 480px) {
    .chat-new__cora{
        height: 70% !important;
    }

    .cora-input{
        width: 80%;
    }

    .main-cora {
        min-width: 200px;
        width: 100vw;
        height: 100vh;
        top: 0%;
        left: 0%;
        border-radius: 0;
        transform: translate(0);
        overflow: hidden;
    }

    .send-cora-img{
        width: 30px;
    }

    .img-smile-cora{
        width: 20px;
    }

    .subtitulo-new__cora{
        font-size: 1.1rem;
    }

    .chat-new__cora{
        height: 77% !important;
    }
}
</style>
<style scoped>
.tag_font_style{
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #e5eef7;
}

.tag_custom_style{
  display: flex;
  height: 4.5%;
  width: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

.homologation_stripe_color {
  background-color: rgb(255, 167, 38);
}

.training_stripe_color {
  background-color: #e20000;
}
</style>
