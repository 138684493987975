<template>
  <div id="modal-confirmar" role="dialog" aria-labelledby="modal-confirmar___BV_modal_title_" aria-describedby="modal-confirmar___BV_modal_body_" class="modal fade show" aria-modal="true" style="display: block;">
     <div class="modal-dialog" role="document">
         <div class="modal-content">
         <div class="header-modal-cora">
             <h5 class="modal-title titulo-modal-cora" id="exampleModalLabel">Confirme seus dados</h5>
             <small class="subtitulo-modal-cora">*Preencha todos os campos vazios e atualize os existentes</small>
         </div>
         <div class="modal-body">
            <div  class="container-modal">
                 <div class="form-group">
                     <label for="InputName" class="labelCora">Nome</label>
                     <input type="text" class="form-cora-input" v-model="pessoa.pessoa.nome" placeholder="Digite seu nome">
                     <small class="text-danger" v-if="textoNome">{{ this.textoNomeDescricao }}</small>
                 </div>
                 <div class="form-group">
                     <label for="InputDateNascimento" class="labelCora">Data de Nascimento</label>
                     <input type="date" class="form-cora-input" v-model="pessoa.pessoa.nascimento" placeholder="Data Nascimento">
                     <small class="text-danger" v-if="textoNascimento">Insira uma data de nascimento.</small>
                 </div>
                 <div class="form-group">
                     <label for="InputGenero" class="labelCora">Gênero</label>
                     <select name="" id="" class="form-cora-input" v-model="pessoa.pessoa.id_genero">
                         <option :value="gen.id_genero" :key="gen.id_genero"  v-for="gen in pessoa.genero">{{ gen.descricao }}</option>
                     </select>
                     <small class="text-danger" v-if="textoGenero">Insira um gênero.</small>
                 </div>
                 <div class="form-group">
                     <label for="InputTelefone" class="labelCora" >Telefone</label>
                     <input type="text" v-mask="'(##) #####-####'" v-model="pessoa.pessoa.telefone" class="form-cora-input" placeholder="Opcional">
                     <small class="text-danger">Informação Opcional</small>
                 </div>
                 <div class="form-group">
                     <label for="InputGenero" class="labelCora">Estado Civil</label>
                     <select name="" id="" class="form-cora-input" v-model="pessoa.pessoa.id_estado_civil">
                         <option :value="est.id_estado_civil" :selected="est.descricao == pessoa.estado_civil" v-for="est in pessoa.estado_civil">{{ est.descricao }}</option>
                     </select>
                     <small class="text-danger" v-if="textoEstadoCivil">Insira um estado civil válido.</small>
                 </div>
                 <div class="form-group">
                     <label for="InputGenero" class="labelCora">Escolaridade</label>
                     <select name="" id="" class="form-cora-input" v-model="pessoa.pessoa.id_escolaridade" required>
                         <option :value="esc.id_escolaridade" :selected="esc.descricao == pessoa.pessoa.escolaridade" v-for="esc in pessoa.escolaridade">{{ esc.descricao }}</option>
                     </select>
                     <small class="text-danger" v-if="textoEscolaridade">Insira uma escolaridade válida.</small>
                 </div>
                 <div class="form-group">
                     <label for="InputNumeroFilhos" class="labelCora">Número de Filhos</label>
                     <input type="number" class="form-cora-input" placeholder="Opcional" v-model="pessoa.pessoa.numero_filhos">
                     <small class="text-danger">Informação Opcional</small>
                 </div>
                 <div class="form-group">
                     <label for="inputRendaBruta" class="labelCora">Renda Bruta</label>
                     <CurrencyInput
                         v-model="rendaBruta"
                         :options="currencyOptions"
                         class="form-cora-input"
                         placeholder="Opcional"
                     />
                     <small class="text-danger">Informação Opcional</small>
                 </div>
            </div>
         </div>
         <div class="modal-footer justify-content-center">
             <button type="button" @click="concluirSalvar()" :class="{'botao-modal-cora':btn.length == 0, 'botao-modal-cora-desativado':btn.length > 0}" :disabled="btn.length != 0">Concluir</button>
         </div>
         </div>
     </div>
 </div>
</template>

<script>

import {
 BRow,
 BCol,
 BFormGroup,
 BContainer,
 BFormInput
} from 'bootstrap-vue';
import CurrencyInput from '@core/components/currency-input/CurrencyInput.vue'

export default {
components:{
 BRow,
 BCol,
 BFormGroup,
 BContainer,
 BFormInput,
 CurrencyInput
},

props: {
 pessoa: {
   default: false,
   required: true,
 }
},

data(){
 return{
   btn: [],
   nome: null,
   textoNome: false,
   textoNomeDescricao: '',
   textoEscolaridade: false,
   textoNascimento: false,
   textoEstadoCivil: false,
   textoGenero:false,
   telefone: null,
   rendaBruta: null,
 }
},

computed: {
 currencyOptions(){
     return {
         currency: 'BRL',
         currencyDisplay: 'symbol',
         hideCurrencySymbolOnFocus: false,
         hideGroupingSeparatorOnFocus: false,
         hideNegligibleDecimalDigitsOnFocus: false,
         autoDecimalDigits: true,
         useGrouping: true,
     }
 }
},

methods:{
 concluirSalvar(){
     this.pessoa.pessoa.renda_bruta = this.rendaBruta;
   this.$emit('concluirSalvar', this.pessoa);
 },

 removerValidacao(valor){
   let index = this.btn.indexOf(valor);
   if (index !== -1) {
     this.btn.splice(index, 1);
   }
 }
},

watch:{
 'pessoa.pessoa.nome': function(val){
   if(val.length == ''){
     this.textoNome = true;
     this.btn.push('nome');
     this.textoNomeDescricao = 'Insira um nome';
   }else if(val.length > 255){
      this.textoNomeDescricao = 'O nome deve ter no maximo 255 caracteres';
      this.textoNome = true;
      this.btn.push('nome');
   }
   else{
     this.textoNome = false;
     this.removerValidacao('nome');
   }
 },
 'pessoa.pessoa.id_genero':{
    handler: function(val){
     if(val == null || val == 'vazio'){
       this.textoGenero = true;
       this.btn.push('genero')
     }else{
       this.textoGenero = false;
       this.removerValidacao('genero');
     }
   },
   immediate: true
 },
 'pessoa.pessoa.nascimento':{
   handler: function(val){
     if(val == null || val == ''){
       this.textoNascimento = true;
       this.btn.push('nascimento')
     }else{
       this.textoNascimento = false;
       this.removerValidacao('nascimento');
     }
   }
 },
 'pessoa.pessoa.id_escolaridade': {
   handler: function(val){
     if(val == null || val == 'vazio'){
       this.textoEscolaridade = true;
       this.btn.push('escolaridade')
     }else{
       this.textoEscolaridade = false;
       this.removerValidacao('escolaridade');
     }
   },
   immediate: true
 },
 'pessoa.pessoa.id_estado_civil':{
    handler: function(val){
     if(val == null || val == 'vazio'){
       this.textoEstadoCivil = true;
       this.btn.push('estado_civil')
     }else{
       this.textoEstadoCivil = false;
       this.removerValidacao('estado_civil');
     }
   },
   immediate: true
 },
 'pessoa.pessoa.renda_bruta':{
   handler: function(val){
     val = parseFloat(val);
     if(val>=0){
         this.rendaBruta = parseFloat(val);
     }
   },
   immediate: true
 },
},
}

</script>

<style scoped>
#modal-confirmar{
    height: 90vh;
}
.header-modal-cora{
 height: 110px;
 padding: 3%;
 background: linear-gradient(45deg,#1265BB .32%,#4EA4FE 100%);
 border-radius: 6px 6px 0 0;
 border: .2px solid #1265BB;
 border-bottom: none;
}
.subtitulo-modal-cora{
 font-family: Montserrat,serif;
 font-weight: 300;
 font-size: 1.1rem;
 color: #fff;
}
.titulo-modal-cora{
 font-family: Montserrat,serif;
 font-weight: 300;
 font-size: 3rem;
 color: #fff;
}
.modal .modal-header .close {
 display: none;
}

.modal-body{
 padding: 0;
 border-left: 1px solid #1265BB;
 border-right: 1px solid #1265BB;
 border-top: 1px solid #1265BB;
}

/* FORM */
.container-modal{
 padding: 3%;
 background-color: #e5eef7;
}
.modal-footer{
 background-color: #e5eef7;
 border-left: 1px solid #1265BB;
 border-right: 1px solid #1265BB;
 border-bottom: 1px solid #1265BB;
}
.form-cora-input{
 padding: 1%;
 height: 24.5px;
 font-family: Montserrat,serif;
 font-weight: 300;
 font-size: 0.9rem;
 border: 0;
 outline: 0;
 width: 100%;
}
.labelCora{
 font-family: Montserrat,serif;
 font-weight: bold;
 font-size: 0.9rem;
 color: black;
}
.botao-modal-cora{
 width: 45%;
 max-width: 45%;
 min-height: 30px;
 background-color: #002060;
 color: #fff;
 border-radius: 20px;
 box-shadow: 0 4px 8px #0000001f;
 justify-content: center;
 align-items: center;
 font-family: Montserrat,serif;
 font-weight: 300;
 cursor: pointer;
 margin-top: 2%;
 border: none;
 font-size: 1.1rem;
}
.form-group{
  margin-bottom: .5rem ;
}
.botao-modal-cora-desativado{
 width: 45%;
 max-width: 45%;
 min-height: 30px;
 background-color: #00206180;
 color: #fff;
 border-radius: 20px;
 box-shadow: 0 4px 8px #0000001f;
 font-size: 1.1rem;
 border: none;
 justify-content: center;
 align-items: center;
 font-family: Montserrat,serif;
 font-weight: 300;
 cursor: default;
 margin-top: 2%;
}


@media (max-width: 768px) {
  #modal-confirmar{
        height: 104vh !important;
        top: 0% !important;
    }
}

@media (max-width: 600px) {
  .titulo-modal-cora{
    font-size: 2rem;
  }
}

@media (min-height: 500px) and (max-height: 661px) {
    #modal-confirmar{
        height: 104vh !important;
        top: -9% ;
    }

    .label {
      margin-bottom: 0.1257rem !important;
    }

    .form-group{
        margin-bottom: .1rem !important;
    }
    .header-modal-cora{
        padding: 2% !important;
    }
    .form-cora-input{
        height: 21.5px !important;
        padding: 0.7%;
    }
    .container-modal{
      padding: 1.3% !important;
    }
    .header-modal-cora{
      padding: 2% !important;
      height: 90px !important;
    }
}

@media (min-height: 662px) and (max-height:800px) {
    #modal-confirmar{
        height: 100vh ;
        top: -6% ;
    }
    .form-group{
        margin-bottom: .3rem;
    }

    .form-cora-input{
        height: 21.5px !important;
        padding: 0.7%;
    }
    .modal-footer{
      padding: 0px !important;
    }
    .container-modal{
      padding: 1.3% !important;
    }

    .header-modal-cora{
      padding: 2% !important;
      height: 90px !important;
    }

}

@media (max-width: 400px) {
 .form-group{
     margin-bottom: 0.1rem !important;
 }
 #modal-confirmar{
     margin-top: 5px !important;
 }
}


</style>
